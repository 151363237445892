<template>
  <section class="app-section-root-fees">
    <AppSection title="Fees and limits" additionalsStyles="flex-col md:flex-row">
      <div slot="tollbar" class="tabs flex border border-blue-300 dark:border-blue-500 rounded-[4px] mt-[10px] md:mt-[0]">
        <router-link class="tab-item block py-[13px] uppercase tracking-[0.04em] font-medium text-[12px] leading-[15px] text-decoration-none w-[100px] transition" to="/exchange/wallet/fees/all">All</router-link>
        <router-link class="tab-item block py-[13px] uppercase tracking-[0.04em] font-medium text-[12px] leading-[15px] text-decoration-none w-[100px] transition" to="/exchange/wallet/fees/fiat">Fiat</router-link>
        <router-link class="tab-item block py-[13px] uppercase tracking-[0.04em] font-medium text-[12px] leading-[15px] text-decoration-none w-[100px] transition" to="/exchange/wallet/fees/crypto">Crypto</router-link>
      </div>
      <div slot="content">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </AppSection>
  </section>
</template>

<script>
import AppSection from '@/components/_common/AppSection/AppSection';

export default {
  components: {
    AppSection,
  },
};
</script>

<style lang="scss" scoped>
.app-section-root-fees {
  height: calc(100vh - 104px);
}
</style>
